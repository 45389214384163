import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/esm/plugins/remove_button/plugin'
import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'
import { post } from '@rails/request.js'

//const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = [ 'input', 'item']


    connect() {
        this.initializeTomSelect();
    }

    disconnect() {
        this.destroyTomSelect();
    }


    initializeTomSelect() {
        // Return early if no element is associated with the controller.
        if (!this.element) return;

        const allowCreate = this.inputTarget.dataset.tomselectCreate === 'true'; // Check if the dataset attribute is set to 'true'
        const controller = this.inputTarget.dataset.tomselectController;
        const attribute = this.inputTarget.dataset.tomselectAttribute;
        const model = this.inputTarget.dataset.tomselectModel;
        let allowEmpty = this.inputTarget.dataset.tomselectAllowEmpty;
        if (allowEmpty === undefined) {
            allowEmpty = 'true';
        }
        allowEmpty = allowEmpty === 'true'; // Convert to boolean        // Fetch options from the select element
        const options = Array.from(this.inputTarget.options).map(option => {
            return {
                value: option.value,
                text: option.text,
                subtext: option.dataset.subtext // Fetch the data-subtext attribute
            };
        });

        let plugins = {};
        if (allowCreate) {
            plugins.remove_button = {
                title: 'Entfernen'
            };
        }

        // if placeholder is set, use it, otherwise use the default
        let placeholder = this.inputTarget.dataset.placeholder ? this.inputTarget.dataset.placeholder : "Bitte auswählen";

        this.tomselect = new TomSelect(this.inputTarget, {
            plugins: plugins,
            persist: false,
            allowEmptyOption: allowEmpty,
            create: (input, callback) => {
                post(`/backend/${controller}`, {
                    body: JSON.stringify({ [model]: { title: input } }),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    responseKind: 'turbo-stream'
                })
            },
            placeholder: placeholder,
            options: options,
            openOnFocus: true, //
            preload: 'focus', //
            closeAfterSelect: true, //
            selectOnTab: true,
            maxOptions: 100, // limit the number of options
            render: {
                no_results: function (data, escape) {
                    return '<div class="no-results">Keine Ergebnisse für "' + escape(data.input) + '". Entfernen mit der ␛-Taste</div>';
                },
                option_create: function(data, escape) {
                    if (allowCreate) {
                        return '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen &hellip;</div>';
                    } else {
                        return ''; // Return an empty string to hide the "Add" option
                    }
                },
                option: function(data, escape) {
                    let subtext = data.subtext ? '<br/><span class="subtext text-xs text-gray-400 truncate">' + escape(data.subtext) + '</span>' : '';
                    return '<div class="option">' +
                        '<span class="title text-sm">' + escape(data.text) + '</span>' +
                        subtext +
                        '</div>';
                },
                item: function(data, escape) {
                    return '<div class="item text-sm">' + escape(data.text) + '</div>';
                },
                render: {
                    loading_more: function(data, escape) {
                        return `<div class="loading-more-results py-2 d-flex align-items-center"><div class="spinner"></div> Wird geladen</div>`;
                    }
                },
            },
            onInitialize: () => {
                this.inputTarget.classList.remove('hidden');
            }
        });

    }

    itemTargetConnected(element) {
        this.tomselect.sync()
        this.tomselect.setValue(element.value)
    }

    destroyTomSelect() {
        this.tomselect.destroy(this.inputTarget)
    }
}